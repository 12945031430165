<template>
    <div class="wrapper">
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <div class="container">
            <h1 class="title__wrap">Введите название банка</h1>

            <v-autocomplete
                v-model="selectedBank"
                :items="suggestions"
                :search-input.sync="search"
                :loading="isLoading"
                item-text="value"
                placeholder="Введите название банка"
                return-object
                clearable
                solo
                hide-no-data
                no-filter
                class="autocomplete"
            >
            </v-autocomplete>

            <p class="politic">
                Выберите банк к которому привязан ваш номер телефона. В случае победы эти данные
                понадобятся для выплаты приза.
            </p>

            <v-btn block :disabled="!selectedBank" :loading="isSending" @click="saveBankName"
                >Далее</v-btn
            >
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";

import HeaderNav from "@/components/HeaderNav.vue";

export default {
    components: { HeaderNav },
    data() {
        return {
            search: null,
            selectedBank: null,
            suggestions: [],
            isLoading: false,
            isSending: false,
        };
    },
    created() {
        this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, 800);
    },
    watch: {
        search(val) {
            if (val) {
                this.isLoading = true;
                this.debouncedFetchSuggestions(val);
            } else {
                this.suggestions = [];
                this.isLoading = false;
            }
        },
    },
    methods: {
        async fetchSuggestions(query) {
            try {
                const { data } = await axios({
                    method: "POST",
                    url: process.env.VUE_APP_DADATA_URL,
                    data: { query, type: ["BANK"], count: 5 },
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Token " + process.env.VUE_APP_DADATA_API,
                    },
                });

                const filteredBank = data.suggestions.map((el) => el.value);

                this.suggestions = filteredBank;
            } catch (error) {
                console.error("Ошибка при получении подсказок:", error);
            } finally {
                this.isLoading = false;
            }
        },

        async saveBankName() {
            try {
                if (!this.selectedBank) return;
                this.isSending = true;

                const { data } = await axios.post(`${process.env.VUE_APP_MYHOST}`, {
                    email: this.$store.state.registerEmail,
                    password: this.$store.state.registerPassword,
                    bankName: this.selectedBank,
                });

                this.$store.commit("isAcitvetedUser", false);
                this.$store.commit("changeEmail", this.$store.state.registerEmail);
                this.$store.commit("changeIsAuth", true);
                this.$cookies.set("cng_token", data.jwt);
                this.$router.push({ name: "approveaccount" }).catch(() => {});
            } catch (error) {
                console.log(error.message);
            } finally {
                this.isSending = false;
            }
        },
    },
};
</script>

<style scoped>
.container {
    padding: 30px;
}

.autocomplete {
    width: 100%;
}

.title__wrap {
    font: 700 30px "PT Sans";
    text-align: center;
    line-height: 110%; /* 44px */
    letter-spacing: -0.2px;
}

.email-field {
    width: 100%;
}

.politic {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper {
        padding: 0;

        display: flex;
        justify-content: center;
    }

    .container {
        max-width: 800px;
        width: 100%;
        padding: 80px;
    }
}
</style>
