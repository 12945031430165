var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebarContainer"},[_c('v-img',{staticClass:"header__img",attrs:{"lazy-src":require("../assets/logo.png"),"max-height":"50","max-width":"100","src":require("../assets/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'profile' }).catch(() => {})}}}),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[(!this.$store.state.isActivetedUser)?_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/approve-email',
                    notActiveItem: _vm.$route.path !== '/approve-email',
                },on:{"click":function($event){_vm.$router.push({ name: 'approve-email' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Подтвердить email")])],1):_vm._e(),(this.$store.state.isActivetedUser)?_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/profile',
                    notActiveItem: _vm.$route.path !== '/profile',
                },attrs:{"aria-selected":"true"},on:{"click":function($event){_vm.$router.push({ name: 'profile' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Мой профиль")])],1):_vm._e(),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/upload',
                    notActiveItem: _vm.$route.path !== '/upload',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'LcUpload' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Загрузить новый чек")])],1),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/lc/',
                    notActiveItem: _vm.$route.path !== '/lc/',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'LcMain' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Загруженные чеки")])],1),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/notification',
                    notActiveItem: _vm.$route.path !== '/notification',
                }},[_c('v-list-item-title',{staticClass:"sidebar-notifications",on:{"click":function($event){_vm.$router.push({ name: 'notifications' }).catch(() => {})}}},[_vm._v("Уведомления "),_c('div',{staticClass:"sidebar-notifications__counter"},[_vm._v(" "+_vm._s(this.$store.state.filteredNotification)+" ")])])],1),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/prize',
                    notActiveItem: _vm.$route.path !== '/prize',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'prize' }).catch(() => {})}}},[_c('v-list-item-title',{staticClass:"sidebar-notifications"},[_vm._v("Вручение призов "),_c('div',{staticClass:"sidebar-notifications__counter"},[_vm._v(" "+_vm._s(this.$store.state.prizeList.length)+" ")])])],1),_c('v-list-item',{class:{ notActiveItem: _vm.$route.path !== '/support' }},[_c('a',{attrs:{"href":'mailto:support@promo-pena.ru',"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Поддержка")])],1)])],1)],1),_c('p',{staticClass:"sidebar-exitBtn",on:{"click":_vm.exit}},[_vm._v("Выйти")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }